import { isBlank } from 'adready-api/helpers/common';
import { get } from 'vuex-pathify';
import advertiserReportsAPI from '~/api/advertiser-reports';
import { DEMO_ADVERTISERS, KEY_DEMO_ADVERTISER_ID } from '~/constant';
import { buildQueryString } from '~/helpers/global/url-helpers';
import { getCampaignDateRange, isDemoInstance, isFlamingoInstance } from '~/util/utility-functions';

const computed = {
  dates: get('dashboard/dates'),
};

const methods = {
  async fetchIos() {
    try {
      if (
        !this.account ||
        !this.advertiser ||
        isBlank(this.account.name) ||
        isBlank(this.advertiser.name) ||
        isBlank(this.advertiser.xandrAdvertiserId)
      ) {
        this.$store.set('dashboard/filters@ioOptions', []);
        this.$store.set('dashboard/filters@adGroupOptions', []);
        return;
      }
      const campaigns = await advertiserReportsAPI.ioNames(
        this.advertiser ? this.advertiser.id : 0,
        buildQueryString({
          advertiser: this.advertiser ? this.advertiser.name : '',
          client: this.account ? this.account.name : '',
          includeNonFlip: isFlamingoInstance(),
        })
      );
      if (!campaigns.length) {
        this.$store.set('dashboard/filters@ioOptions', []);
        this.$store.set('dashboard/filters@adGroupOptions', []);
        return;
      }
      // Mark recent campaigns as default checked
      const advertiserKeys = Object.keys(DEMO_ADVERTISERS).map((k) => parseInt(k, 10));
      const demoAdvertiserId = parseInt(localStorage.getItem(KEY_DEMO_ADVERTISER_ID), 10);
      const demoAdvertiserMapping = advertiserKeys.includes(demoAdvertiserId)
        ? DEMO_ADVERTISERS[demoAdvertiserId]
        : DEMO_ADVERTISERS[0];
      // Mark recent campaigns as default checked
      if (isDemoInstance()) {
        for (let i = 0; i < campaigns.length; i++) {
          if (demoAdvertiserMapping.selectedCampaign.includes(campaigns[i].value)) {
            campaigns[i].checked = true;
          } else {
            campaigns[i].checked = false;
          }
        }
      } else {
        for (let i = 0; i < campaigns.length; i++) {
          campaigns[i].checked = campaigns[i].isRecent;
        }
      }
      this.$store.set('dashboard/filters@ioOptions', campaigns);
      if (!isDemoInstance()) {
        const dateRange = getCampaignDateRange(campaigns, true);
        this.$store.set('dashboard/dates', {
          ...this.dates,
          ...dateRange,
        });
      }

      if (campaigns.length > 1) {
        const campaignIds =
          campaigns
            .filter((e) => e.checked)
            ?.map((e) => e.key)
            ?.join(',') || '';
        this.fetchAdGroupOptions(campaignIds);
      }
    } catch (err) {
      this.$store.set('dashboard/filters@ioOptions', []);
      this.$store.set('dashboard/filters@adGroupOptions', []);
      console.error('error fetching ios ->', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
      throw err;
    }
  },
  async fetchAdGroupOptions(campaignIds) {
    const adGroupData = await advertiserReportsAPI.adGroupOptions(
      this.advertiser ? this.advertiser.id : 0,
      campaignIds,
      buildQueryString({ includeNonFlip: isFlamingoInstance() })
    );
    if (adGroupData?.data?.length > 0) {
      for (let i = 0; i < adGroupData.data.length; i++) {
        adGroupData.data[i].checked = true;
        adGroupData.data[i].value = adGroupData.data[i].lineItemName;
      }
      this.$store.set(
        'dashboard/filters@adGroupOptions',
        adGroupData?.data?.length > 0 ? adGroupData.data : []
      );
    } else {
      this.$store.set('dashboard/filters@adGroupOptions', []);
    }
  },
};

export default { methods, computed };
